<template>
  <div class="home" v-loading="loading">
    <img src="../assets/topimg.jpg" alt="" />
    <div class="button" @click="callPhone()">
      <img
        src="https://applet.parkingkj.com/imgs/OneClickCall.png"
        alt=""
        class="image"
      />
      一键呼叫
    </div>
    <TUICallKit 
      :beforeCalling="beforeCalling"
      :afterCalling="afterCalling"
    />
    <!-- 组件默认显示空内容，进入通话后自动显示 -->
  </div>
</template>

<script>
import axios from "axios";
import { TUICallKit, TUICallKitServer } from "../components/TUICallKit/Web";
import * as GenerateTestUserSig from "../components/TUICallKit/Web/demos/basic/public/debug/GenerateTestUserSig.js";
import { TUICallType } from "tuicall-engine-webrtc";
import BMap from "BMap";

export default {
  data() {
    return {
      SDKAppID: 1400795094,
      userID: "",
      SecretKey:
        "39ddb7eeee5fbb4802704506653f394a20ea80b59e5289b3b9fcb17700b98feb",
      userSig: "",
      callUserID: "",
      isCall: true,
      time: 0,
      loading: true,
    };
  },
  components: {
    TUICallKit,
  },
  mounted() {
    // 生成时间戳存本地
    if (!localStorage.getItem("timeStemp")) {
      localStorage.setItem("timeStemp", new Date().getTime());
    }
    this.userID = localStorage.getItem("timeStemp");
    // 本地测试用
    // this.userID='ning';

    this.init();
    this.getService();
    // this.getLocation();
    console.log("参数", this.getParamsNew("code"));
    console.log('测试：',this.getParamsNew("test"))//1表示是测试账户
  },
  methods: {
    getParamsNew(key) {
      let url = window.location.href;
      let search = url.split("?")[1];
      let temData = new URLSearchParams("?" + search);
      if (temData.get(key) == null) {
        console.log("设备id:", null);
        return null;
      }
      // 解决bug将所有的空格替换为+号
      const result = temData.get(key).split(" ").join("+");
      return result;
    },
    // 获取用户位置信息
    getLocation() {
      console.log(navigator.geolocation);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.success, this.error);
      } else {
        alert("您的浏览器不支持使用HTML 5来获取地理位置服务");
      }
    },

    //定位数据获取成功响应
    success(position) {
      var longitude = position.coords.longitude;
      var latitude = position.coords.latitude;
      // 创建地图实例
      var map = new BMap.Map("container");
      // 创建一个坐标对象
      var point = new BMap.Point(longitude, latitude);
      // var point = new BMap.Point('113.96297', '35.05194898');河南省地区用于测试
      // 创建一个地址解析器实例
      var geoCoder = new BMap.Geocoder();
      // 根据坐标获取地址信息
      geoCoder.getLocation(point, (result) => {
        // 解析地址信息，获取省市区信息
        var address = result.addressComponents;
        var province = address.province;
        var city = address.city;
        var district = address.district;
        console.log(province, city, district);
        // 将带有"省"字的省份信息去除省这个字
        let addressFont =
          province
            .split("")
            .filter((res) => res != "省")
            .join("") +
          city +
          district;
        this.isCall = this.getIsCall(addressFont);
      });
    },
    //定位数据获取失败响应
    error(error) {
      switch (error.code) {
        case error.TIMEOUT:
          alert(" 连接超时，请重试 ");
          break;
        case error.PERMISSION_DENIED:
          alert(" 您拒绝了使用位置共享服务，查询已取消 ");
          break;
        case error.POSITION_UNAVAILABLE:
          // alert(
          //   " 亲爱的火星网友，非常抱歉，我们暂时无法为您所在的星球提供位置服务 "
          // );
          break;
      }
    },
    // 调取接口判断用户地址是否正确，正确的话可以拨打电话，否则不可以
    getIsCall(address) {
      var url =
        "http://192.168.2.29:30007/customerdealer/terminal/verificationRemoteCustomer";
      axios({
        method: "get",
        url: url,
        data: {
          code: this.getParamsNew("code"),
          location: address,
        },
      }).then((res) => {
        console.log(res);
        if (res.data.data.length !== 0) {
          return true;
        } else {
          return false;
        }
      });
    },
    // 获取客服电话
    getService() {
      var url = "https://api.park.parking520.com/user/getAllUser";
      let timer=setInterval(() => {
      axios({
        method: "post",
        url: url,
      }).then((res) => {
        console.log(res);
        this.time++;
        if (res.data.data.length !== 0) {
          this.callUserID = res.data.data[0]; //调取接口动态获取的
          
          clearInterval(timer);
        } else {
          if(this.time==1){
            alert("请稍等，正在接通中");
          }
          if(this.time==20){
            clearInterval(timer);
            alert("获取失败，客服不在线");
          }
        }
      });
      },1000)
    },
    // 初始化
    async init() {
      console.log(this.userID);
      const { userSig } = GenerateTestUserSig.genTestUserSig(
        this.userID,
        this.SDKAppID,
        this.SecretKey
      );
      this.userSig = userSig;
      try {
        await TUICallKitServer.init({
          SDKAppID: this.SDKAppID,
          userID: this.userID,
          // userID: 'thxn',//西宁账户
          userSig: this.userSig,
        });
      } catch (error) {
        alert(`初始化失败，原因：${error}`);
      }
    },
    // 呼叫客服
    callPhone() {
      console.log(this.getParamsNew("code"));
      if (this.isCall) {
        console.log(this.getParamsNew("code"), this.callUserID);
        if (this.callUserID!='') {
          axios({
            method: "post",
            url: "https://api.park.parking520.com/callKit/receivingIdentifier",
            data: {
              terminalId: this.getParamsNew("code"), //16355739454930944
              userId: this.callUserID,
              // userId: 'thtest',
            },
          }).then((res) => {
            console.log(res);
            this.call();
          });
        }else{
          alert(`暂无客服在线，请稍后拨打电话！`);
        }
      } else {
        alert(`您所在地址与拨叫车场地址不一致，无法拨打电话！`);
      }
    },
    // 拨打电话
    async call() {
      try {
        await TUICallKitServer.call({
          // userID: this.callUserID,
          userID: 'thtest',
          type: TUICallType.VIDEO_CALL,
        }); // 1v1 通话
        // TUICallKitServer.groupCall({ userIDList: ["xxx"], groupID: "xxx", type: 2 }); // 群组通话
      } catch (error) {
        alert(`拨打失败，原因：${error}`);
      }
    },
    // 通话前的回调
    beforeCalling(type, error) {
      console.log("通话即将开始", type, error);
    },
    // 通话后的回调
    afterCalling() {
      console.log("通话已结束");
    },
  },
};
</script>
  
<style scoped>
img {
  width: 100%;
}
.button {
  width: 3.5rem;
  height: 1.2rem;
  background: #fff;
  font-weight: 700;
  font-size: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.image {
  width: 0.6rem;
  margin-right: 0.4rem;
}
.home {
  width: 100%;
  height: 100%;
}
.phone {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #eee;
}
label {
  font-size: 0.3rem;
  margin-left: 0.6rem;
}
.content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 1.5rem;
}
input {
  width: 60%;
  height: 0.6rem;
  font-size: 0.3rem;
  color: #666;
  padding-left: 0.4rem;
  background-color: #f4f5f9;
  border: none;
  border-radius: 0.4rem;
}
.call {
  width: 1rem;
  height: 0.6rem;
  background-color: rgb(11, 145, 240);
  font-size: 0.3rem;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;
}
</style>
