import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入百度地图
// import BaiduMap from 'vue-baidu-map'

createApp(App).use(store).use(router)
// .use(BaiduMap, {
//   ak: 'i6riFrlsBH5qsKR4KMHbEWU5mYX9KV75'
// })
.mount('#app')
